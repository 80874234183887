import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockingLoaderComponent } from './blocking-loader.component';
import { BaseModule } from '../core/base/base.module';
import { MaterialModule } from '../core/material/material.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [BlockingLoaderComponent],
  imports: [
    BaseModule,
    MaterialModule,
    MatProgressBarModule,
    MatDialogModule
  ],
  exports:[
    BlockingLoaderComponent
  ],
  entryComponents:[
    BlockingLoaderComponent
  ]
})
export class BlockingLoaderModule { }
