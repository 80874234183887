import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/auth.service';

interface AdminUsers {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'sym-ecom-dashboard-prod',
  templateUrl: './ecom-dashboard-prod.component.html',
  styleUrls: ['./ecom-dashboard-prod.component.scss']
})
export class EcomDashboardComponentProd implements OnInit {
  url: any;
  show:boolean = false;
  initialFetch:boolean;
  email: any;
  dashboard: any;
  emailForm: FormGroup
  selectedValue: string;
  adminList: AdminUsers[] = [
    {value: 'CAN00006 - Comfort Chefs', viewValue: 'info@comfortchefs.co.za'},
    {value: 'CAN00002 - Sash', viewValue: 'Shopsashsa@gmail.com'},
    {value: 'CAN00009 - Canary', viewValue: 'nicky.nielsen@symbiotics.co.za'},
    {value: 'CAN00082 - Andy', viewValue: 'andiswe.mkosi@gmail.com'},
    {value: 'CAN00112 - CastleRock Households', viewValue: 'castlerockhouseholds@gmail.com'},
    {value: 'CAN00091 - All things sexy', viewValue: 'Moyoenittah@gmail.com'},
    {value: 'CAN00065 - Napkn Design', viewValue: 'kobus@wisani.co.za'},
    {value: 'CAN00090 - Bargain Bucket', viewValue: 'Bargainbucket00@gmail.com'},
    {value: 'CAN00088 - Letty Handmade', viewValue: 'mapfumoleticia@gmail.com'},
    {value: 'CAN00111 - Totes Babe', viewValue: 'thecroproom@gmail.com'},
    {value: 'CAN00005 - Books and Blocks', viewValue: 'design@booksandblocks.co.za'},
    {value: 'CAN00081 - Qojiji Clothing Co', viewValue: 'latetambongeni@gmail.com'},
    {value: 'CAN00114 - Vapour Clothing', viewValue: 'Vapoursessions@gmail.com'},
    {value: 'CAN00113 - Jtmb Store', viewValue: 'malulekethaboj@gmail.com'},
    {value: 'CAN00001 - BraaiTjom', viewValue: 'chief@braaitjom.co.za'},
    {value: 'CAN00107 - Nicky Test 2', viewValue: 'nickywenielsen@icloud.com'},
    {value: 'CAN00121 - Superlife STC30', viewValue: 'ncuncubongz04@gmail.com'},
    {value: 'CAN00047 - Daisy', viewValue: 'nicky.nielsen@letha-local.com'},
    {value: 'CAN00019 - IBuild Supply', viewValue: 'gideonvds@ibuildsupply.co.za'},
    {value: 'CAN00018 - The Leather Story', viewValue: 'pifsaad@gmail.com'},
    {value: 'CAN00010 - Simplicity Jewellery Design', viewValue: 'Eleanor@jewelofagirl.co.za'},
    {value: 'CAN00007 - BJoyfulCreations', viewValue: 'chantal@bjoyfulcreations.com'},
    {value: 'CAN00050 - Elegante', viewValue: 'Elegantemagnificent@gmail.com'},
    {value: 'CAN00098 - Bosbok', viewValue: 'bosbokclothing21@gmail.com'},
    {value: 'CAN00067 - Letha Deliver', viewValue: 'marius.venter@symbiotics.co.za'},
    {value: 'CAN00108 - Bloom Beauty and Divine', viewValue: 'Bloom.beautyanddivine@gmail.com'},
    {value: 'CAN00103 - Donlegend empire', viewValue: 'bongabhengu@yahoo.com'},
    {value: 'CAN00057 - Sophias Artisanal Soaps', viewValue: 'm.hoohlo@gmail.com'},
    {value: 'CAN00086 - Goldstar', viewValue: 'peterdstv@yahoo.com'},
    {value: 'CAN00109 - Nicky Test 3', viewValue: 'nickywenielsen@icloud.com'},
    {value: 'CAN00101 - One Hunt Only', viewValue: 'Support@onehuntonly.com'},
    {value: 'CAN00127 - mumunplugged', viewValue: 'mumunplugged21@gmail.com'}
  ];
  constructor(
    public auth: AuthService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      userEmail: ['']
    })
    this.sortAdminUsersByCAN()
  }

  sortAdminUsersByCAN(){
    this.adminList.sort((a,b)=>(a.value > b.value) ? 1: -1)
    console.log(this.adminList)
  }

  loadEcomDashboardProd() {
    this.email = this.emailForm.controls.userEmail.value
    console.log('Dashboard', this.email)
    this.initialFetch = true;
    this.auth.getEcomDashboardProd(this.email).subscribe((response) => {
      this.url = response.payload
      console.log(this.url)
      this.initialFetch = false;
      var containerDiv = document.getElementById("ecomEmbeddingContainerProd");
      var options = {
        url: this.url,
        container: containerDiv,
        scrolling: "yes",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
    }   
    , err => {
    }

    );
    if(this.initialFetch !== true){
      this.show = this.show
    } else this.show = !this.show
  }


}
