import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'sym-password-reset-required',
  templateUrl: './password-reset-required.component.html',
  styleUrls: ['./password-reset-required.component.scss']
})
export class PasswordResetRequiredComponent implements OnInit {
  // endPoint = environment.api.userManagement;

  constructor() { }

  ngOnInit(): void {
  }

}
