import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { BaseModule } from '../helpers/core/base/base.module';
import { MaterialModule } from '../helpers/core/material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { BlockingLoaderModule } from '../helpers/blocking-loader/blocking-loader.module';



@NgModule({
  declarations: [DashboardComponent],
  imports: [
    BaseModule,
    MaterialModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    BlockingLoaderModule,
    
  ]
})
export class DashboardModule { }
