import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { LoginService } from '../auth/login/login.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/auth.service';
import { BlockingLoaderService } from '../helpers/blocking-loader/blocking-loader.service';

@Component({
  selector: 'sym-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  url: any;
  email:any;
  dashboard: any;
  emailForm: FormGroup;
  constructor(
    public auth: AuthService,
    public ls: BlockingLoaderService
  ) { }

  ngOnInit(): void {
    this.loadCmisDashboard();
  }

  loadCmisDashboard() {
    this.auth.getCmisDashboard(this.email).subscribe((response) => {
      this.url = response.payload
      console.log(this.url)
      var containerDiv = document.getElementById("cmisEmbeddingContainer");
      var options = {
        url: this.url,
        container: containerDiv,
        scrolling: "yes",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
    }, err => {
    });
  }


}
