import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthLibModule } from '@symbiotics/auth-lib';
import { MatCardModule } from '@angular/material/card';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../helpers/core/material/material.module';
import { ForgotPasswordResetComponent } from './forgot-password/forgot-password-reset/forgot-password-reset.component';
import { PasswordResetRequiredComponent } from './password-reset-required/password-reset-required.component';
import { AuthComponent } from './auth.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password-reset/:user',
    component: ForgotPasswordResetComponent,
  },
  {
    path: 'password-reset-required/:user',
    component: PasswordResetRequiredComponent
  },
];
@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ForgotPasswordResetComponent, PasswordResetRequiredComponent, AuthComponent],
  imports: [
    CommonModule,
    AuthLibModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
  ]
})
export class AuthModule { }
