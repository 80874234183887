

export const environment = {
  production: false,
  api: {
    quicksight_cmis: 'https://dev.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/cmis/embedded/',
    quicksight_dev_ecom: 'https://dev.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/',
    quicksight_uat_ecom: 'https://uat.admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/',
    quicksight_prod_ecom: 'https://admin-portal.cmis.letha-local.co.za/clients/v1/quicksight/ecom/embedded/'
  }
};


