import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcomDashboardComponentUAT } from './ecom-dashboard-uat.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BaseModule } from '../helpers/core/base/base.module';
import { MaterialModule } from '../helpers/core/material/material.module';
import { Router, Routes } from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
{
  path:'ecom-dashboard-uat',
  component: EcomDashboardComponentUAT
}
]

@NgModule({
  declarations: [EcomDashboardComponentUAT],
  imports: [
    BaseModule,
    MaterialModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[EcomDashboardComponentUAT]
})
export class EcomDashboardModuleUAT { }
