import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/auth.service';
@Component({
  selector: 'sym-ecom-dashboard-uat',
  templateUrl: './ecom-dashboard-uat.component.html',
  styleUrls: ['./ecom-dashboard-uat.component.scss']
})
export class EcomDashboardComponentUAT implements OnInit {
  url: any;
  email: any;
  dashboard: any;
  emailForm: FormGroup
  constructor(
    public auth: AuthService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      userEmail: ['']
    })
  }
  loadEcomDashboardUAT() {
    this.email = this.emailForm.controls.userEmail.value
    console.log('email', this.email)
    this.auth.getEcomDashboardUAT(this.email).subscribe((response) => {
      this.url = response.payload
      console.log(this.url)
      var containerDiv = document.getElementById("ecomEmbeddingContainerUAT");
      var options = {
        url: this.url,
        container: containerDiv,
        scrolling: "yes",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(options);
    }, err => {
    }
    );
  }

}
